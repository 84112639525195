import './index.less'
import { Form, Button, Input, Tabs } from 'ant-design-vue'
import defaultSettings from '@/config/defaultSettings'
// Radio,
export default {
  name: 'FormRetrievePasswordEdit',
  props: Object.assign({
    formData: {},
  }),
  created() {
    // 实例创建完成后被立即调用
    console.info('form created')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      console.info('form mounted')
    })
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'FormRetrievePasswordEdit' }),
      selectTab: 'pc',
      timer: '',
      waitSeconds: 0,
    }
  },
  methods: {
    handleCancel() {
      // 取消提交事件发出
      this.$emit('cancel')
      // 重置form内容
      this.form.resetFields()
      this.waitSeconds = 0
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    handleSubmit(e) {
      // 表单提交事件发出
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.passwordag === values.password) {
            values.selectTab = this.selectTab
            if (values.selectTab === 'pc') {
              values.code = values.vCode
            } else if (values.selectTab === 'mc') {
              values.code = values.mcode
            }
            this.$emit('submit', values, this.form)
            // 重置form内容
            // this.form.resetFields()
            // this.waitSeconds = 0
            // if (this.timer) {
            //   clearInterval(this.timer)
            // }
          } else {
            this.$error({
              title: '错误',
              content: '两次密码输入不一致',
            })
          }
        }
      })
    },
    handleTabChange(actionKey) {
      this.selectTab = actionKey
      this.form.resetFields()
    },
    resetWaitSeconds() {
      if (this.waitSeconds > 0) {
        this.waitSeconds = this.waitSeconds - 1
      }
    },
    async handleGetCode() {
      if (this.waitSeconds === 0) {
        clearInterval(this.timer)
        this.waitSeconds = 0
        if (this.selectTab === 'pc') {
          this.form.validateFields(['phone'], async (err, values) => {
            if (!err) {
              var res = await this.$store.dispatch('GetValidCodeByPhone', { phone: this.form.getFieldValue('phone') })
              if (!res.code) {
                this.waitSeconds = 60
                this.timer = setInterval(this.resetWaitSeconds, 1000)
              }
            }
          })
        } else if (this.selectTab === 'mc') {
          this.form.validateFields(['email'], async (err, values) => {
            if (!err) {
              var res = await this.$store.dispatch('GetValidCodeByEmail', { email: this.form.getFieldValue('email') })
              if (!res.code) {
                this.waitSeconds = 60
                this.timer = setInterval(this.resetWaitSeconds, 1000)
              }
            }
          })
        }
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  render() {
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    }
    return (
      <div class="formRetrievePasswordEdit">
        <div class="top">
          <div class="header">
            <a href="/">
              <div>
                <span class="title">{defaultSettings.title}</span>
              </div>
            </a>
          </div>
        </div>
        <Form
          form={this.form}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={this.handleSubmit}
          layout="horizontal"
        >
          <Tabs
            animated={false}
            size="large"
            tabBarGutter={22}
            tabBarStyle={{ borderBottom: '0px solid #FFF' }}
            activeKey={this.selectTab}
            onChange={this.handleTabChange.bind(this)}
          >
            <Tabs.TabPane forceRender={true} key="pc" tab="使用手机找回">
              <Form.Item label="">
                {getFieldDecorator('phone', {
                  rules: [...(this.selectTab === 'pc' ? this.$validator.phone.rules : [])],
                })(<Input size="large" placeholder="请输入手机号" />)}
              </Form.Item>
              <Form.Item label="">
                {getFieldDecorator('vCode', {
                  rules: [
                    {
                      required: this.selectTab === 'pc',
                      message: '请输入验证码',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder="请输入验证码"
                    addonAfter={
                      <a onClick={this.handleGetCode.bind(this)} style={this.waitSeconds > 0 ? { color: 'gray' } : {}}>
                        获取验证码{this.waitSeconds > 0 ? `（${this.waitSeconds}）` : ''}
                      </a>
                    }
                  />
                )}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
          <Form.Item label="">
            {getFieldDecorator('password', {
              ...this.$validator.complexPwdFun('新密码'),
            })(<Input.Password placeholder="请输入新密码" size="large" />)}
          </Form.Item>
          <Form.Item label="">
            {getFieldDecorator('passwordag', {
              ...this.$validator.requiredAndIsSame(
                this.form.getFieldValue('password'),
                '请输入确认新密码',
                '两次密码输入不一致'
              ),
            })(<Input.Password placeholder="再次输入新密码" size="large" />)}
          </Form.Item>
          <Form.Item labelCol={tailFormItemLayout.labelCol} wrapperCol={tailFormItemLayout.wrapperCol}>
            <div class="btnBroup">
              <Button
                size="large"
                type="default"
                class="btnItem"
                onClick={this.handleCancel}
                style={{ marginRight: '20px' }}
              >
                取消
              </Button>
              <Button size="large" type="primary" htmlType="submit" class="btnItem">
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  },
}
