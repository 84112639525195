import './index.less'
import FormLogin from './components/FormLogin'
import { mapActions } from 'vuex'
import router from '@/router/index'
import storage from 'store'
import { ORGANIZATION_ID } from '@/store/mutation-types'

export default {
  name: 'Login',
  mounted() {
    this._checkHasOrganizationId()
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'LoginWithSms', 'GetUserAllPermission']),
    // 检查url中是否携带orgid
    async _checkHasOrganizationId() {
      const routeOrgId = this.$route.query?.orgId

      if (routeOrgId) {
        await storage.set(ORGANIZATION_ID, routeOrgId)
      }

      const orgId = await storage.get(ORGANIZATION_ID)
      if (!orgId) {
        this.$router.push('/404')
      }
    },
    async handleForm(todo, dForm) {
      const params = {
        ...dForm.values,
        client: process.env.VUE_APP_CLIENT,
        domainType: process.env.VUE_APP_DOMAIN_CODE,
        organizationId: storage.get(ORGANIZATION_ID)
      }
      if (todo === 'submit') {
        var res
        // 原来这里有 mc 邮箱, pc: 手机, ap: 账号。。。
        res = await this.Login(params)
        if (!res?.code) {
          await this.GetUserAllPermission()
          // 登录成功
          router.push({ path: '/' })
        }
      }
    }
  },
  render() {
    return (
      <div class="Login">
        <FormLogin onSubmit={this.handleForm.bind(this, 'submit')} />
      </div>
    )
  }
}
