import './index.less'
import { Form, Button, Input, Tabs } from 'ant-design-vue'
import logo from '@/assets/logo.png'
import defaultSettings from '@/config/defaultSettings'
// Radio,
export default {
  name: 'FormUserRegister',
  props: Object.assign({
    formData: {}
  }),
  created() {
    // 实例创建完成后被立即调用
    console.info('form created')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      console.info('form mounted')
    })
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'FormUserRegister' }),
      selectTab: 'pc',
      timer: '',
      waitSeconds: 0
    }
  },
  methods: {
    handleCancel() {
      // 取消提交事件发出
      this.$emit('cancel')
      // 重置form内容
      this.form.resetFields()
      this.waitSeconds = 0
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    handleSubmit(e) {
      // 表单提交事件发出
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.selectTab = this.selectTab
          if (values.selectTab === 'pc') {
            values.code = values.pcode
            values.username = values.phone
          } else if (values.selectTab === 'mc') {
            values.code = values.mcode
            values.username = values.email
          }
          values.domainCode = 'clfront'
          this.$emit('submit', values)
          // 重置form内容
          this.form.resetFields()
          this.waitSeconds = 0
          if (this.timer) {
            clearInterval(this.timer)
          }
        }
      })
    },
    handleTabChange(actionKey) {
      this.selectTab = actionKey
      this.form.resetFields()
    },
    resetWaitSeconds() {
      if (this.waitSeconds > 0) {
        this.waitSeconds = this.waitSeconds - 1
      }
    },
    async handleGetCode() {
      if (this.waitSeconds === 0) {
        clearInterval(this.timer)
        this.waitSeconds = 0
        if (this.selectTab === 'pc') {
          this.form.validateFields(['phone'], async (err, values) => {
            if (!err) {
              var res = await this.$store.dispatch('GetValidCodeByPhone', { phone: this.form.getFieldValue('phone') })
              if (res && res.code === 1000) {
                this.waitSeconds = 60
                this.timer = setInterval(this.resetWaitSeconds, 1000)
              }
            }
          })
        } else if (this.selectTab === 'mc') {
          this.form.validateFields(['email'], async (err, values) => {
            if (!err) {
              var res = await this.$store.dispatch('GetValidCodeByEmail', { email: this.form.getFieldValue('email') })
              if (res && res.code === 1000) {
                this.waitSeconds = 60
                this.timer = setInterval(this.resetWaitSeconds, 1000)
              }
            }
          })
        }
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  render() {
    // const { formData = {} } = this.$props
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 24,
          offset: 0
        }
      }
    }
    return (
      <div class='formUserRegister'>
        <div class="top">
          <div class="header">
            <a href="/">
              <div>
                <img src={logo} class="logo" alt="logo" />
              </div>
              <div>
                <span class="title">{defaultSettings.title}</span>
              </div>
            </a>
          </div>
        </div>
        {/* <h2>
          找回密码</h2>
        <p>
          使用手机/邮箱找回密码
        </p>
        <Divider style={{ margin: '20px 0 20px' }} /> */}
        <Form form={this.form}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={this.handleSubmit} layout='horizontal'>
          <Tabs
            animated={false}
            size='large'
            tabBarGutter={22}
            tabBarStyle={{ borderBottom: '0px solid #FFF' }}
            activeKey={this.selectTab}
            onChange={
              this.handleTabChange.bind(this)}>
            <Tabs.TabPane forceRender={true} key='pc' tab='使用手机号注册'>
              <Form.Item label="手机号" >
                {getFieldDecorator('phone', {
                  ...this.$validator.phone
                })(<Input size='large' placeholder='请输入手机号' />)}
              </Form.Item>
              <Form.Item label="验证码" >
                {getFieldDecorator('pcode', {
                  rules: [
                    {
                      required: this.selectTab === 'pc',
                      message: '请输入验证码'
                    }
                  ]
                })(<Input
                  size='large'
                  placeholder='请输入验证码'
                  addonAfter={<a
                    onClick={this.handleGetCode.bind(this)}
                    style={this.waitSeconds > 0 ? { color: 'gray' } : {}}>获取验证码{this.waitSeconds > 0 ? `（${this.waitSeconds}）` : ''}</a>} />)}
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} key='mc' tab='使用邮箱注册'>
              <Form.Item label="邮箱" >
                {getFieldDecorator('email', {
                  ...this.$validator.email
                })(<Input size='large' placeholder='请输入邮件地址' />)}
              </Form.Item>
              <Form.Item label="验证码" >
                {getFieldDecorator('mcode', {
                  rules: [
                    {
                      required: this.selectTab === 'mc',
                      message: '请输入验证码'
                    }
                  ]
                })(<Input
                  size='large'
                  placeholder='请输入验证码'
                  addonAfter={<a
                    onClick={this.handleGetCode.bind(this)}
                    style={this.waitSeconds > 0 ? { color: 'gray' } : {}}>获取验证码{this.waitSeconds > 0 ? `（${this.waitSeconds}）` : ''}</a>} />)}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
          {/* <Form.Item >
            {getFieldDecorator('domainCode', {
              rules: [
                {
                  required: true,
                  message: '请选择系统域名'
                }
              ]
            })(
              <Radio.Group >
                <Radio value="clsys_back">后台系统</Radio>
                <Radio value="clsys_front">前台系统</Radio>
              </Radio.Group>)}
          </Form.Item>
           <Form.Item  label="姓名" >
            {getFieldDecorator('realname', {
              rules: [
                {
                  required: true,
                  message: '姓名不可为空'
                }
              ]
            })(<Input maxLength={10}  placeholder='请输入姓名' size='large' />)}
          </Form.Item>
          <Form.Item  label="密码" >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: '新密码不可为空'
                }
              ]
            })(<Input.Password placeholder='请输入密码' size='large' />)}
          </Form.Item> */}
          <Form.Item
            labelCol={tailFormItemLayout.labelCol}
            wrapperCol={tailFormItemLayout.wrapperCol} >
            <div class='btnBroup'>
              <Button size='large' type="default" class='btnItem' onClick={this.handleCancel} style={{ marginRight: '20px' }}>
                取消
               </Button>
              <Button size='large' type="primary" htmlType="submit" class='btnItem'>
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div >
    )
  }
}
