import './FormLogin.less'
import { Form, Button, Input } from 'ant-design-vue'
import defaultSettings from '@/config/defaultSettings'

export default {
  name: 'FormLogin',
  created() {
    // 实例创建完成后被立即调用
  },
  mounted() {
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
    })
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'formEditUser' }),
    }
  },
  methods: {
    handleSubmit(e) {
      // 表单提交事件发出
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('submit', { form: this.form, values })
        }
      })
    },
  },
  render() {
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    }

    return (
      <div class="formLogin">
        <div class="top">
          <div class="header">
            <a href="/">
              <div>
                <span class="title">{defaultSettings.title}</span>
              </div>
            </a>
          </div>
        </div>
        <Form {...formItemLayout} form={this.form} onSubmit={this.handleSubmit} layout="horizontal">
          <Form.Item>
            {getFieldDecorator('userName', {
              rules: [{ required: true, message: '请输入账号' }],
            })(<Input size="large" placeholder="请输入账号" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入密码' }],
            })(<Input.Password size="large" placeholder="请输入密码" />)}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <div class="btnBroup">
              <Button type="primary" htmlType="submit" class="btnItem">
                登录
              </Button>
            </div>
            <div style={{ width: '100%' }}></div>
            <div class="linkbtn">
              <a
                type="link"
                onClick={() => {
                  this.$router.push({ path: '/user/retrievePassword' })
                }}
              >
                忘记密码
              </a>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  },
}
