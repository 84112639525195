import './index.less'
import { Card } from 'ant-design-vue'
import FormRetrievePasswordEdit from './components/FormUserRegister/index'
export default {
  name: 'UserRegister',
  created() {
    // 实例创建完成后被立即调用
    this.$loadStoreToData(this, 'UserRegisterModule')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      this.loadData()
    })
  },
  data() {
    return {

    }
  },
  methods: {
    async loadData() {
    },
    async handleForm(todo, formData) {
      console.info(formData)
      if (todo === 'submit') {
        var res
        if (formData.selectTab === 'pc') {
          res = await this.$store.dispatch('UserRegisterModule/registerWitSms', formData)
        } else if (formData.selectTab === 'mc') {
          res = await this.$store.dispatch('UserRegisterModule/registerWitEmail', formData)
        }
        // code old if (res && res.code === 1000) {
        if (!res.code) {
          this.$success({
            title: '用户注册成功',
            content: '请重新登录？',
            onOk: () => {
              this.$router.push({
                path: '/user/login'
              })
            }
          })
        }
      } else if (todo === 'cancel') {
        this.$router.push({
          path: '/user/login'
        })
      }
    }
  },

  render() {
    return (
      <div class='userRegister'>
        <Card bordered={false}>
          <div class='formBox'>
            <FormRetrievePasswordEdit
              formData={this.selectedItem}
              onSubmit={this.handleForm.bind(this, 'submit')}
              onCancel={this.handleForm.bind(this, 'cancel')} />
          </div>
          {/* <div class="footer">
            <div class="copyright">&copy; {new Date().getFullYear()} 北京齿轮易创科技有限公司</div>
          </div> */}
        </Card>
      </div>
    )
  }
}
