import './index.less'
// import { Card } from 'ant-design-vue'
import FormRetrievePasswordEdit from './components/FormRetrievePasswordEdit/index'
export default {
  name: 'RetrievePassword',
  created() {
    // 实例创建完成后被立即调用
    this.$loadStoreToData(this, 'RetrievePasswordModule')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      this.loadData()
    })
  },
  data() {
    return {}
  },
  methods: {
    async loadData() {},
    async handleForm(todo, formData, form) {
      if (todo === 'submit') {
        const params = {
          ...formData,
          domainCode: process.env.VUE_APP_DOMAIN_CODE,
          domainType: process.env.VUE_APP_DOMAIN_CODE,
        }
        const res = await this.$store.dispatch('RetrievePasswordModule/resetPasswordWithPhone', params)
        // code old if (res && res.code === 1000) {
        if (!res.code) {
          this.$success({
            title: '密码修改成功',
            content: '请重新登录？',
            onOk: () => {
              this.$router.push({
                path: '/user/login',
              })
            },
          })
        } else if (res.code === 999) {
          if (res.data) {
            var eks = Object.keys(res.data)
            if (res.data.code) {
              eks.forEach((element) => {
                form.setFields({
                  pcode: {
                    value: form.getFieldValue('pcode'),
                    errors: [new Error(res.data[element])],
                  },
                })
              })
            } else if (res.data.phone) {
              form.setFields({
                phone: {
                  value: form.getFieldValue('phone'),
                  errors: [new Error(res.data['phone'])],
                },
              })
              this.$notification.error({
                message: '系统错误',
                description: res.data.phone,
              })
            }
          }
        }
      } else if (todo === 'cancel') {
        this.$router.push({
          path: '/user/login',
        })
      }
    },
  },

  render() {
    return (
      <div class="RetrievePassword">
        <div class="formBox">
          <FormRetrievePasswordEdit
            formData={this.selectedItem}
            onSubmit={this.handleForm.bind(this, 'submit')}
            onCancel={this.handleForm.bind(this, 'cancel')}
          />
        </div>
      </div>
    )
  },
}
